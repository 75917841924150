import { Box } from "@mui/material";
import { pictureList, pictureBorderColors } from "./pictureList";
import ViewHeader from "../ViewHeader/ViewHeader";
import "./Galerie.css";

const Galerie = () => {
  return (
    <div className="basic-view">
      <ViewHeader text={"Galerie"} />
      <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
        {pictureList.map((pic, i) => (
          <div className="galerie">
            <div
              className="pic-box"
              style={{ borderColor: `${pictureBorderColors[i % 7]}` }}
            >
              <img src={pic} alt="galerie-pic" />
            </div>
          </div>
        ))}
      </Box>
    </div>
  );
};
export default Galerie;
