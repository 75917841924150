import PictureCarousel from "../../components/Carousel/Carousel";
import ResponsiveBox from "../../components/ResponsiveBox/ResponsiveBox";
import ViewHeader from "../ViewHeader/ViewHeader";
import { Typography } from "@mui/material";
import youtube from "../../utils/images/youtube.avif";
import ImageBox from "../Sortiment/ProductInfoBox/ImageBox/ImageBox";
import CheckIcon from "@mui/icons-material/Check";

const Home = () => {
  return (
    <div className="basic-view home">
      <ViewHeader text={"Wir werken mit Holz"} />
      <ResponsiveBox
        first={
          <Typography sx={{ fontSize: { xs: "25px", sm: "35px" } }}>
            Kurz & knapp:
            <br />
            <span style={{ color: "#f6ce0f" }}>Kinderleichte</span>{" "}
            <span style={{ color: "#cd303d" }}>halbfertige</span>{" "}
            <span style={{ color: "#4feb34" }}>Holz-Bausätze</span> für jeden,
            der Freude am Holzbasteln hat oder diese entdecken möchte.
            <br />
            Geeignet für Grundschulen, Kindergärten und andere
            Kindereinrichtungen
          </Typography>
        }
        second={<PictureCarousel />}
      />
      <br />
      <a href="flyer.pdf" download="flyer_wirwerkenmitholz.pdf">
        <h1>Aktueller Flyer in PDF-Format. Hier klicken zum Download!</h1>
      </a>
      <br />
      <ResponsiveBox
        second={
          <Typography sx={{ fontSize: { xs: "25px", sm: "35px" } }}>
            <span style={{ textDecorationLine: "underline" }}>
              Vorteile unserer Produkte
            </span>{" "}
            <br />
            <CheckIcon /> 100% Naturprodukt
            <br />
            <CheckIcon />
            Kinderleichte Verarbeitung
            <br />
            <CheckIcon />
            Fördert Kreativität und Feinmotorik
            <br />
            <CheckIcon />
            Individuelles Angebot für Kinder ab 3 bis ca. 15 Jahre
          </Typography>
        }
        first={
          <a
            href="https://www.youtube.com/watch?v=n2iFv2EVgkA"
            target="_blank"
            rel="noreferrer"
          >
            <ImageBox image={youtube} />
            <h1>Hier der Link zu unserem Youtube Video</h1>
          </a>
        }
      />
    </div>
  );
};
export default Home;
