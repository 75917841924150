import pic1 from "../../utils/images/galery1.avif";
import pic2 from "../../utils/images/galery2.avif";
import pic3 from "../../utils/images/galery3.avif";
import pic4 from "../../utils/images/galery4.avif";
import pic5 from "../../utils/images/galery5.avif";
import pic6 from "../../utils/images/galery6.avif";
import pic7 from "../../utils/images/galery7.avif";
import pic8 from "../../utils/images/galery8.avif";
import pic9 from "../../utils/images/galery9.avif";
import pic10 from "../../utils/images/galery10.avif";
import pic11 from "../../utils/images/galery11.avif";
import pic12 from "../../utils/images/galery12.avif";
import pic13 from "../../utils/images/galery13.avif";
import pic14 from "../../utils/images/galery14.avif";
import pic15 from "../../utils/images/galery15.avif";
import pic16 from "../../utils/images/galery16.avif";

export const pictureList = [
  pic8,
  pic9,
  pic4,
  pic5,
  pic6,
  pic7,
  pic10,
  pic11,
  pic12,
  pic1,
  pic2,
  pic3,
  pic13,
  pic14,
  pic15,
  pic16,
];

export const pictureBorderColors = [
  "#f6ce0f",
  "#cd303d",
  "#4feb34",
  "#56acbd",
  "#ec813a",
  "#ec008b",
  "#d6df23",
];
