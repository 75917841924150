import { Box } from "@mui/material";

const ImageBox = ({ image }) => {
  return (
    <Box
      component="img"
      src={image}
      sx={{
        width: { xs: "90vw", sm: "45vw" },
        maxWidth: "600px",
        height: "auto",
      }}
    />
  );
};
export default ImageBox;
