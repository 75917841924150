import React from "react";
import ViewHeader from "../ViewHeader/ViewHeader";
import { Typography } from "@mui/material";

const Kontakt = () => {
  return (
    <div className="basic-view kontakt-container">
      <div calssName="kontakt-section">
        <ViewHeader text={"Kontakt"} />
        <Typography sx={{ fontSize: { xs: "20px", sm: "25px" } }}>
          Name: Adam Helis
          <br />
          Telefon: +49 152 07854170
          <br />
          E-Mail: info@wirwerkenmitholz.de
          <br />
          <br />
          Ich stehe Ihnen gerne bei allen Fragen zur Verfügung.
          <br />
          Kontaktieren sie mich!
        </Typography>
      </div>
    </div>
  );
};

export default Kontakt;
