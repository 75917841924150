import Kreisel from "../../utils/images/product1.avif";
import Kleiderhaken1 from "../../utils/images/product2.avif";
import Vogelhäuschen from "../../utils/images/product13.avif";
import Holzwürfel from "../../utils/images/product8.avif";
import Rennauto from "../../utils/images/product7.avif";
import Segelboot from "../../utils/images/product6.avif";
import Kleiderhaken2 from "../../utils/images/product3.avif";
import Flugzeug from "../../utils/images/product4.avif";
import Engel from "../../utils/images/product9.avif";
import Blume from "../../utils/images/product11.avif";
import Blockhäuschen from "../../utils/images/product10.avif";
import Serviettenhalter from "../../utils/images/product12.avif";
import Schleifklotz from "../../utils/images/product14.avif";
import Hamsterhäuschen from "../../utils/images/product5.avif";
import Nistkasten1 from "../../utils/images/product15.avif";
import Nistkasten2 from "../../utils/images/product16.avif";

export const productList = [
  {
    image: Flugzeug,
    name: "Flugzeug",
    info: "Fichtenholz (30x30x9 cm) 4,50 €",
  },
  {
    image: Rennauto,
    name: "Rennauto-Formel",
    info: "Fichten- + Lindenholz (13x9x6 cm) 3,00 €",
  },
  {
    image: Segelboot,
    name: "Segelboot",
    info: "Fichten- + Buchenholz (25x6x19 cm) 3,70 €",
  },
  {
    image: Serviettenhalter,
    name: "Serviettenhalter",
    info: "Fichtenholz, ohne Anstrich (13x8x4cm) 2,10 €",
  },
  {
    image: Kreisel,
    name: "Kreisel",
    info: "Linden und Fichtenholz (Durchmesser 4 cm, Länge 10 cm) 1,19 €",
  },
  {
    image: Vogelhäuschen,
    name: "Vogelhäuschen",
    info: "Fichtenholz + Dachpappe (19x15x17 cm) 7,00 €",
  },
  {
    image: Nistkasten1,
    name: "Nistkasten I",
    info: "Fichtenholz + Dachpappe (14x14x24 cm) 8,50 €",
  },
  {
    image: Nistkasten2,
    name: "Nistkasten II",
    info: "Fichtenholz + Dachpappe (23x21x33 cm) 10,00 €",
  },
  {
    image: Blockhäuschen,
    name: "Blockhäuschen",
    info: "Fichtenholz, ohne Anstrich, 28-teilig (9x10x7) 3,60 €",
  },
  {
    image: Hamsterhäuschen,
    name: "Hamsterhäuschen",
    info: "Fichtenholz (18x14x11 cm) 3,50 €",
  },
  {
    image: Kleiderhaken1,
    name: "Kleiderhaken I",
    info: "Fichtenholz (25x5x6 cm) 3,00 €",
  },
  {
    image: Kleiderhaken2,
    name: "Kleiderhaken II",
    info: "Fichtenholz (35x8x6 cm) 3,70 €",
  },
  {
    image: Holzwürfel,
    name: "Holzwürfel",
    info: "Fichtenholz (3x3 cm) 0,40 €",
  },
  {
    image: Schleifklotz,
    name: "Schleifklotz",
    info: "Fichtenholz + Schleifpapier, ohne Anstrich (3x11 cm) 0,60 €",
  },
  {
    image: Engel,
    name: "Engel",
    info: "Fichten und Lindenholz (14x2x15 cm) 2,50 €",
  },
  {
    image: Blume,
    name: "Blume",
    info: "Buchen- und Lindenholz, ohne Anstrich (38x11 cm) 2,40 €",
  },
];
