import React from "react";
import "./App.css";
import ResponsiveAppBar from "./components/AppBar/AppBar";
import Home from "./views/Home/Home";
import Sortiment from "./views/Sortiment/Sortiment";
import Kontakt from "./views/Kontakt/Kontakt";
import { Routes, Route } from "react-router-dom";
import Galerie from "./views/Galerie/Galerie";
import Leistungen from "./views/Leistungen/Leistungen";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

const App = () => {
  return (
    <div className="App">
      <ScrollToTop />
      <ResponsiveAppBar />
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/sortiment" element={<Sortiment />} />
        <Route path="/leistungen" element={<Leistungen />} />
        <Route path="/galerie" element={<Galerie />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
