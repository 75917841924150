import { Box, Typography } from "@mui/material";

const InfoBox = ({ name, info }) => {
  return (
    <Box
      sx={{
        width: { sm: "30vw" },
        margin: { xs: "0 50px", sm: "0 50px 0 0" },
        fontSize: { xs: "15px", sm: "120px" },
      }}
    >
      <Typography
        sx={{ fontWeight: "bold", fontSize: { xs: "20px", sm: "25px" } }}
      >
        {name}
      </Typography>
      <Typography sx={{ fontSize: { xs: "20px", sm: "25px" } }}>
        {info}
      </Typography>
    </Box>
  );
};
export default InfoBox;
