import { Box } from "@mui/material";
import ViewHeader from "../ViewHeader/ViewHeader";
import ProductInfoBox from "./ProductInfoBox/ProductInfoBox";
import { productList } from "./productList";

const Sortiment = () => {
  return (
    <div className="basic-view">
      <Box>
        <ViewHeader text={"Sortiment"} />
        <Box>
          {productList.map((product) => (
            <ProductInfoBox product={product} />
          ))}
        </Box>
      </Box>
    </div>
  );
};
export default Sortiment;
