import { Box } from "@mui/material";

const ResponsiveBox = ({ first, second, styles }) => {
  const Element = ({ element }) => (
    <Box
      sx={{
        width: { xs: "90vw", md: "45vw" },
        margin: "0 2.5vw",
        height: "min-content",
      }}
    >
      {element}
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        ...styles,
      }}
    >
      <Element element={first} />
      <Element element={second} />
    </Box>
  );
};
export default ResponsiveBox;
