import { Box } from "@mui/material";

const Footer = () => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bottom: "0",
        backgroundColor: "#4feb34",
        width: "100vw",
        textAlign: "center",
        minHeight: {
          xs: "60px",
          sm: "54px",
        },
      }}
    >
      Wir werken mit Holz - Inhaber: Adam Helis - Wetzelstraße 1, 96047
      Bamberg - Tel.: 0152-07854170
    </Box>
  );
};
export default Footer;
