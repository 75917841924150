import { Typography } from "@mui/material";

const ViewHeader = ({ text }) => {
  return (
    <Typography
      component="h1"
      sx={{
        textAlign: "center",
        margin: "25px 0",
        fontSize: { xs: "25px", sm: "50px" },
      }}
    >
      {text}
    </Typography>
  );
};
export default ViewHeader;
