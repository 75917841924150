import Carousel from "react-gallery-carousel";
import pic1 from "../../utils/images/product1.avif";
import pic2 from "../../utils/images/product2.avif";
import pic3 from "../../utils/images/product3.avif";
import pic4 from "../../utils/images/product4.avif";
import pic5 from "../../utils/images/product5.avif";
import pic6 from "../../utils/images/product6.avif";
import pic7 from "../../utils/images/product7.avif";
import pic8 from "../../utils/images/product8.avif";

import "react-gallery-carousel/dist/index.css";

const PictureCarousel = () => {
  const images = [pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8].map(
    (img) => ({
      src: img,
    })
  );

  return (
    <Carousel
      style={{
        width: { sm: "90vw", md: "45vw" },
        height: { sm: "60vw", md: "30vw" },
      }}
      images={images}
      isAutoPlaying
      autoPlayInterval={3500}
      hasMediaButton
      hasIndexBoard={false}
    />
  );
};

export default PictureCarousel;
