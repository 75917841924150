import ViewHeader from "../ViewHeader/ViewHeader";
import { Typography } from "@mui/material";

const Leistungen = () => {
  return (
    <div className="basic-view leistungen">
      <ViewHeader text={"Leistungen"} />
      <Typography sx={{ fontSize: { xs: "20px", sm: "25px" } }}>
        Möchten Sie ein Angebot? Wenden Sie sich vertrauensvoll an uns!
        <br />
        Nach einer Absprache mit Ihnen gestalten wir für Ihre Klasse gerne auch
        einen interdisziplinären Projektunterricht, an dem Kinder die
        Möglichkeit bekommen mit unseren Produkten zu basteln und mehr von dem
        Holz als (Bau) Material zu erfahren (Wir bringen alle Materialien mit).
        <br />
        Wir freuen uns auf Ihren Anruf! Setzen Sie sich bei Interesse mit uns
        gern in Verbindung und zwar Per Telefon, Email oder Kontaktformular.
      </Typography>
    </div>
  );
};
export default Leistungen;
